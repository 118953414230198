export const accessorialColumns = [
  {
    label: "  Accessorials",
    field: "description",
    minWidth: 80
  },
  {
    label: "charge",
    field: "total",
    minWidth: 60
  },
  {
    label: "Notes",
    field: "notes",
    minWidth: 100
  },
];

export const accessorialsQuoteDrayage = {
  chsSubtotal: "Chassis Sub Total",
  fuelSurcharge: "Fuel Surcharge",
  hazardous: "Hazmat",
  layover: "Layover",
  lineHaul: "Line Haul",
  overweight: "Overweight",
  prepull: "Prepull",
  reefer: "Reefer",
  price: "Total Price",
};

export const HazardousOptions = [
  { label: "1.1", value: "1.1" },
  { label: "1.2", value: "1.2" },
  { label: "1.3", value: "1.3" },
  { label: "1.4", value: "1.4" },
  { label: "1.5", value: "1.5" },
  { label: "1.6", value: "1.6" },
  { label: "2.1", value: "2.1" },
  { label: "2.2", value: "2.2" },
  { label: "2.3", value: "2.3" },
  { label: "3", value: "3" },
  { label: "4.1", value: "4.1" },
  { label: "4.2", value: "4.2" },
  { label: "4.3", value: "4.3" },
  { label: "5.1", value: "5.1" },
  { label: "5.2", value: "5.2" },
  { label: "6.1", value: "6.1" },
  { label: "6.2", value: "6.2" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
];
