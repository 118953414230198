<template>
  <div class="drayage-table">
    <template v-if="screenWidth > 800">
      <el-table
        ref="quoteTable"
        style="width: 100%"
        height="50vh"
        :scrollbar-always-on="true"
        :border="false"
        :filter-multiple="true"
        :data="renderCarriers"
        default-expand-all
        highlight-current-row
      >
        <template #empty>
          <NotFoundData />
        </template>
        <el-table-column type="expand">
          <template>
            <MoreDetailsDrayage />
          </template>
        </el-table-column>
        <el-table-column
          v-for="(column, index) in columns"
          :key="index"
          :label="column.label"
          :prop="column.field"
          :min-width="column.minWidth"
        >
        <template slot-scope="{ row }">
            <div v-if="column.field === 'carrier'" class="carrier-container">
              <img :src="row.carrier_image" alt="Carrier Image" class="carrier-image" />
            </div>
            <div v-if="column.field === 'expiration_date'">
              <div class="disclaimer-info" v-if="row.disclaimer && row.disclaimer.trim() !== ''">
                <i class="ion ion-information-circle-outline disclaimer-icon"></i>
                <span>{{ row.disclaimer }}</span>
              </div>
            </div>
            <div v-if="column.field === 'price_total'" class="buttons-container">
              <DrayageButtonsAcceptance
                :row="row"
                :aditionalInsurance="aditionalInsurance"
                :priceWithInsurance="priceWithInsurance"
                :loadingInsurance="loadingInsurance"
                :chosenLoadById="chosenLoadById"
                @optionSelected="optionSelectedCarrier"
              />
            </div>
                        <div v-else>
              {{ row[column.field] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <template v-else>
      <DrayageListResponsive
        :renderCarriers="renderCarriers"
        :aditionalInsurance="aditionalInsurance"
        :priceWithInsurance="priceWithInsurance"
        :loadingInsurance="loadingInsurance"
        :chosenLoadById="chosenLoadById"
        @optionSelected="optionSelectedCarrier"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import onResize from "../../../../../../../Extend/onResize";
import DrayageButtonsAcceptance from "./DrayageButtonsAcceptance.vue";
import MoreDetailsDrayage from "./MoreDetailsDrayage.vue";
import NotFoundData from "../../../../../../Dashboard/components/NotFoundData.vue";
import UpdateAditionalInsurance from "../../../../../../../utils/updateAditionalInsurance";
import DrayageListResponsive from './DrayageListResponsive.vue';

export default {
  name: "DrayageTable",
  components: {
    DrayageButtonsAcceptance,
    NotFoundData,
    MoreDetailsDrayage,
    DrayageListResponsive,
  },
  mixins: [onResize],
  data() {
    return {
      aditionalInsurance: 35,
      loadingInsurance: false,
      isPickup: false,
      originalCarrierArray: [],
      showModal: false,
      carrierData: [],
      carriersDrayage: [],
      renderCarriers: [],
      priceWithInsurance: 0,
      carrierSelected: null,
      loyaltyInsurance: false,
      chosenLoadById: null,
      disclaimer: "",
      columns: [
        {
          label: "Carrier Service",
          field: "carrier",
          minWidth: 100,
          enableCopy: true,
          sortable: true,
        },
        {
          label: "Expiration Date",
          field: "expiration_date",
          minWidth: 90,
          enableCopy: true,
          sortable: true,
        },
        {
          label: "Price total",
          field: "price_total",
          minWidth: 155,
          enableCopy: true,
          sortable: true,
        },
      ],
    };
  },
  async created() {
    const cargoValue = this.$store.getters["carriers/getChosenLoadCargoValue"];
    this.chosenLoadById = this.$store.getters["load/getChosenLoad"];
    this.aditionalInsurance = UpdateAditionalInsurance(cargoValue);
    this.recalculatePriceWithInsurance();
  },
  computed: {
    ...mapGetters({
      chosenLoad: 'drayage/getChosenLoad',
      carriers: 'drayage/getCarriers',
      chosenLoadCargoValue: "carriers/getChosenLoadCargoValue",
    })
  },
  watch: {
    chosenLoad: {
      handler(newValue) {
        if (newValue) {
          this.setCarriersInfo();
        } else {
          this.getDrayageCarriers();
        }
      },
      immediate: true
    },
    carriers: {
      handler(newValue) {
        if (newValue && !this.chosenLoad) {
          this.setCarriersInfo();
        }
      },
      immediate: true
    },
    chosenLoadById: {
      handler(newValue) {
        if (newValue?.stops?.[0]?.location_id) {
          this.fetchDisclaimer();
        }
      },
      immediate: true,
    },
    chosenLoadCargoValue() {
      this.aditionalInsurance = UpdateAditionalInsurance(this.chosenLoadCargoValue);
    },
    carrierData: {
      deep: true,
      immediate: true,
      handler() {
        this.recalculatePriceWithInsurance();
      },
    },
    aditionalInsurance: {
      immediate: true,
      handler() {
        this.recalculatePriceWithInsurance();
      },
    },
  },
  methods: {
    recalculatePriceWithInsurance() {
      const basePrice = this.carrierData?.price || 0;
      const additional = this.aditionalInsurance || 0;
      this.priceWithInsurance = Math.round((basePrice + additional) * 100) / 100;
    },
    async getDrayageCarriers() {
      if (!this.chosenLoad) {
        const { loadId } = this.$route.params;
        await this.$store.dispatch("drayage/getCarriers", { load_id: loadId });
      }
    },
    async fetchDisclaimer() {
      try {
        const locationId = this.chosenLoadById?.stops?.[0]?.location_id;
        const response = await this.$store.dispatch("drayage/getPortByLocation", locationId);
        this.disclaimer = response?.disclaimer;
      } catch (error) {
        console.error("Error fetching disclaimer:", error);
      }
    },
    setCarriersInfo() {
      this.carrierData = this.chosenLoad || this.carriers;
      if (!this.carrierData?.price) {
        this.renderCarriers = [];
        return;
      }
      this.renderCarriers = [{
        carrier: this.carrierData.carrier,
        expiration_date: this.carrierData.expirationDate,
        price_total: this.carrierData.price,
        // eslint-disable-next-line global-require
        carrier_image: require("../../../../../../../assets/images/Truck & Drayage_3.png"),
        disclaimer: this.disclaimer,
      }];
    },
    optionSelectedCarrier(params) {
      this.loadingInsurance = true;
      this.isPickup = params.buyPickup;
      this.loyaltyInsurance = params.enableLoyaltyInsurance;
      // eslint-disable-next-line no-underscore-dangle
      this.carrierSelected = this.carrierData._id;
      this.chosenLoadById.insurance_required = params.insurance;
      this.chosenLoadById.carrierName = this.carrierData.carrier;
      this.$store.commit("load/setChosenLoad", this.chosenLoadById);
      if (params.changeView) {
        this.changeView("StopsInfo", this.loyaltyInsurance, this.isPickup, params.insurance);
      }
      this.loadingInsurance = false;
    },
    changeView(routeName, loyaltyInsurance, pickup, insurance) {
      const id = this.carrierSelected;
      this.$router.push({
        name: routeName,
        params: {
          loadId: this.$route.params.loadId,
          carrierId: id,
          pickup,
          insurance,
        },
        query: {
          pickup,
          insurance,
          ...(loyaltyInsurance === true && { loyaltyInsurance: true })
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drayage-table {
  @include table-design(3);
}

.carrier-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carrier-image {
  width: 50%;
  margin: 10px 0;
}
.disclaimer-info {
  display: flex;
  text-align: left;
  align-items: flex-start;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 5px;
  color: black;
  font-weight: bold;
}
.disclaimer-icon {
  font-weight: bold;
  margin-right: 5px;
  color: $color-secondary-button-border;
}

::v-deep .cell {
  text-align: center;
  font-size: 14px;
}

::v-deep .el-table__row .el-table__cell {
  border-bottom: none !important;
}

@media (max-width: 800px) {
  ::v-deep .buttons-container {
    button {
      max-width: none;
      height: auto;
      padding: 8px;
      margin: 10px 0px;
      .container-info__label {
        max-width: none;
        margin: 0px;
        text-align: start;
      }
      .container-info {
        justify-content: space-between;
      }
    }
  }
}
</style>
