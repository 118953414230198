<template>
    <div class="list-detail">
      <h3 class="list-detail__title">{{ title }}</h3>
      <table class="detail-table">
        <thead>
          <tr>
            <th v-for="col in columns" :key="col.field" :style="{ minWidth: col.minWidth + 'px' }">
              {{ col.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td v-for="col in columns" :key="col.field">
              {{ item[col.field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>

<script>
export default {
  name: 'DetailTable',
  props: {
    title: {
      type: String,
    },
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>

.list-detail {
  width: 95%;
  margin: 15px;
  &__title{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin: 0;
    border-radius: 10px 10px 0px 0px;
    background-color: #ebeef5;
    color: $color-primary-company;
  }
}

.detail-table {
  width: 100%;
  tr {
    border-top: 1px solid $color-secondary-button-border;
    border-bottom: 1px solid $color-secondary-button-border;
  }
  th {
    font-weight: bold;
    background-color: #ebeef5;
    color: $color-primary-company;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    vertical-align: middle;
  }
}

@media (max-width: 800px) {
  .list-detail {
    width: auto;
  }
}
</style>
