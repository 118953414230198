<template>
  <div class="drayage-container-detail">
    <!-- Desktop View -->
    <template v-if="screenWidth > 800">
      <DetailTable
        title="Accessorial charges that may apply to your load"
        :columns="accessorialColumns"
        :data="formattedAccessorials"
      />
    </template>

    <!-- Mobile View -->
    <template v-else>

      <div class="collapsible-section">
        <div class="section-header" @click="toggleSection('accessories')">
          <span>Accessorial charges that may apply to your load</span>
          <i :class="['fas', isExpanded.accessories ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
        </div>
        <div v-show="isExpanded.accessories" class="section-content">
          <DetailTable
            :columns="accessorialColumns"
            :data="formattedAccessorials"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import onResize from "../../../../../../../Extend/onResize";
import DetailTable from './DetailTableDrayage.vue';
import { accessorialColumns } from "../../../../../../../utils/DrayageDetails";

export default {
  name: "MoreDetailsDrayage",
  components: {
    DetailTable
  },
  mixins: [onResize],
  data() {
    return {
      isExpanded: {
        quote: false,
        accessories: false
      },
      accessorialColumns,
    };
  },

  computed: {
    ...mapGetters({
      chosenLoad: 'drayage/getChosenLoad',
      carriers: 'drayage/getCarriers'
    }),

    formattedAccessorials() {
      const carrierData = this.getCarrierData();
      const accessorials = carrierData?.drayageAccessorials || [];

      return accessorials.map((accessorial) => this.formatAccessorialItem(accessorial));
    }
  },
  methods: {
    toggleSection(section) {
      this.isExpanded[section] = !this.isExpanded[section];
    },
    formatCurrency(value) {
      return `$${value || 0}`;
    },
    getCarrierData() {
      return this.chosenLoad || this.carriers || null;
    },

    formatAccessorialItem(accessorial) {
      return {
        description: accessorial.description,
        total: this.formatCurrency(accessorial.value),
        notes: accessorial.unit || ''
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.drayage-container-detail {
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
}
.collapsible-section {
    margin-bottom: 16px;
    border: 1px solid $color-border-container;
    border-radius: 15px;
    overflow: hidden;

    .section-header {
      padding: 12px 16px;
      background: $color-gray-light;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        background: darken($color-gray-light, 5%);
      }
    }

    .section-content {
      padding: 16px;
      background: $color-white;
    }
  }

</style>
