<template>
  <div class="drayage-buttons">
    <!-- Tabla de Total Quote -->
    <div class="list-detail">
      <table class="detail-table">
        <tbody>
          <tr v-for="(item, index) in formattedQuote" :key="index" :class="{ 'total-price-item': item.description === 'Total Price' }">
            <td>{{ item.description }}</td>
            <td>{{ item.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="drayage-buttons__container">
      <!-- Botón BOOK -->
    <div id="drayage-book-wrapper">
      <button
        class="quote-button quote-button--blue"
        :class="{ 'disabled-button': loadingInsurance || disableEditQuoteButton }"
        :disabled="loadingInsurance || disableEditQuoteButton"
        @click="handleSelectedCarrier(false)"
      >
        <div class="container-info" v-if="!loadingInsurance">
          <div class="container-info__label">
            <p>BOOK</p>
          </div>
          <div class="container-info__prices">
            <span>USD ${{ price }}</span>
          </div>
        </div>
        <b-spinner small v-if="loadingInsurance" variant="light" />
      </button>
      <b-popover target="drayage-book-wrapper" triggers="hover" placement="top">
        <div v-if="disableEditQuoteButton">
          {{ $t("carriers.editDrayageQuoteDisabledMessage") }}
        </div>
      </b-popover>
    </div>

    <!-- Botón BOOK WITH INSURANCE -->
    <div id="drayage-book-insurance-wrapper">
      <button
        class="quote-button quote-button--green"
        :class="{ 'disabled-button': loadingInsurance || disableEditQuoteButton }"
        :disabled="loadingInsurance || disableEditQuoteButton"
        @click="handleSelectedCarrier(true)"
      >
        <div class="container-info" v-if="!loadingInsurance">
          <div class="container-info__label">
            <p>BOOK WITH INSURANCE</p>
          </div>
          <div class="container-info__prices">
            <span>USD ${{ priceWithInsurance }}</span>
          </div>
        </div>
        <b-spinner small v-if="loadingInsurance" variant="light" />
      </button>
      <b-popover target="drayage-book-insurance-wrapper" triggers="hover" placement="top">
        <div v-if="disableEditQuoteButton">
          {{ $t("carriers.editDrayageQuoteDisabledMessage") }}
        </div>
      </b-popover>
    </div>
    </div>

    <!-- Modal para aceptación de cotización -->
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
    >
      <QuoteAcceptanceModal
        :insurance="insurance"
        :aditionalInsurance="aditionalInsurance"
        :carrier="carrierInfo"
        :lineItems="lineItems"
        @closeModal="closeModal"
        @optionSelected="emitOptionSelected"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuoteAcceptanceModal from "../../QuoteAcceptanceModal.vue";
import { accessorialsQuoteDrayage } from "../../../../../../../utils/DrayageDetails";

export default {
  name: "DrayageButtonsAcceptance",
  components: {
    QuoteAcceptanceModal
  },
  props: {
    priceWithInsurance: {
      type: Number,
    },
    aditionalInsurance: {
      type: Number,
      default: 35
    },
    loadingInsurance: {
      type: Boolean,
      default: false,
    },
    lineItems: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
      default: () => {},
    },
    chosenLoadById: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      insurance: false,
      accessorialsQuoteDrayage
    };
  },
  computed: {
    ...mapGetters({
      carrierInfo: "drayage/getCarrierInfo",
    }),
    price() {
      return this.carrierInfo?.price?.toFixed(2) || "0.00";
    },
    disableEditQuoteButton() {
      return !this.chosenLoadById?.origin_portal_quote;
    },
    formattedQuote() {
      const carrierData = this.carrierInfo;
      if (!carrierData) return [];

      return Object.entries(this.getQuoteLabels())
        .filter(([key]) => Boolean(carrierData[key]))
        .map(([key, label]) => this.formatQuoteItem(key, label, carrierData[key]));
    }
  },
  methods: {
    handleSelectedCarrier(withInsurance) {
      this.insurance = withInsurance;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    emitOptionSelected(params) {
      this.$emit("optionSelected", {
        ...params,
        changeView: true
      });
      this.closeModal();
    },
    formatCurrency(value) {
      return `$${value || 0}`;
    },
    formatQuoteItem(key, label, value) {
      return {
        description: label,
        total: this.formatCurrency(value)
      };
    },
    getQuoteLabels() {
      return accessorialsQuoteDrayage;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.drayage-buttons {
  &__container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
.container-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: clamp(0.6rem, 2vw, 0.7rem);
  line-height: normal;
  &__label {
    margin: auto;
    width: 50%;
    max-width: 95px;
    font-size: 12px;
    word-break: break-word;
    p {
      margin: 0px;
    }
  }
  &__prices {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 95px;
    text-align: center;
    word-break: break-all;
    letter-spacing: 0.5px;
    span {
      margin: auto 0;
      font-size: 12px;
    }
    p {
      font-weight: bold;
    }
    label {
      font-size: 10px;
    }
  }
}
.quote-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  min-width: 170px;
  margin: 4px;
  padding: 5px;
  height: 43px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
  &.disabled-button {
    background: $color-disabled-button;
    color: $color-white;
  }
}

.list-detail {
  margin: 15px;
}

.detail-table {
  width: 100%;
  margin-top: 15px;
  th {
    font-weight: bold;
    color: black;
  }
  tr{
    background-color: transparent;
  }
  td {
    padding: 5px;
    text-align: left;
    font-size: 14px;
    vertical-align: middle;
  }
}

.total-price-item {
  font-weight: bold;
  border-top: 1px solid gainsboro;
  color: black;
}

@media (max-width: 800px) {
  .list-detail {
    width: auto;
  }
}
</style>
