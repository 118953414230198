<template>
  <div>
    <template>
      <div class="mobile-drayage-list">
        <div v-if="renderCarriers.length === 0">
          <NotFoundData />
        </div>

        <div v-else v-for="carrier in renderCarriers" :key="carrier.carrier" class="mobile-carrier-card">
          <div class="mobile-carrier-header">
            <span class="carrier-label">Carrier Service</span>
            <span class="carrier-value">{{ carrier.carrier }}</span>
          </div>

          <div class="mobile-carrier-body">
            <div class="carrier-info">
              <span class="carrier-label">Expiration Date</span>
              <span class="carrier-value">{{ carrier.expiration_date }}</span>
            </div>

            <div class="mobile-carrier-body">
              <div  class="carrier-info disclaimer-info">
                <i class="ion ion-information-circle-outline disclaimer-icon"></i>
                <span>{{ carrier.disclaimer }}</span>
              </div>
            </div>

            <div class="carrier-info">
              <span class="carrier-label">Price Total</span>
              <span class="carrier-value">${{ carrier.price_total }}</span>
            </div>
          </div>

          <div class="mobile-carrier-actions">
            <DrayageButtonsAcceptance
              :aditionalInsurance="aditionalInsurance"
              :priceWithInsurance="priceWithInsurance"
              :loadingInsurance="loadingInsurance"
              :chosenLoadById="chosenLoadById"
              @optionSelected="optionSelectedCarrier"
            />
          </div>

          <div class="mobile-carrier-details">
            <MoreDetailsDrayage />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DrayageButtonsAcceptance from "./DrayageButtonsAcceptance.vue";
import MoreDetailsDrayage from "./MoreDetailsDrayage.vue";
import NotFoundData from "../../../../../../Dashboard/components/NotFoundData.vue";

export default {
  name: "DrayageTableResponsive",
  components: {
    DrayageButtonsAcceptance,
    NotFoundData,
    MoreDetailsDrayage,
  },
  props: {
    renderCarriers: {
      type: Array,
      required: true
    },
    priceWithInsurance: {
      type: Number,
    },
    aditionalInsurance: {
      type: Number,
      default: 35
    },
    loadingInsurance: {
      type: Boolean,
      default: false
    },
    chosenLoadById: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    optionSelectedCarrier(params) {
      this.$emit('optionSelected', params);
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-drayage-list {
  .mobile-carrier-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border: 1px solid rgb(163, 163, 163);
    border-radius: 15px;
    background: $color-white;
    padding: 5%;
    margin-bottom: 16px;

    .mobile-carrier-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 3%;
      padding-bottom: 8px;
      border-bottom: 2px solid $color-gray-dark;
      .carrier-label {
          font-weight: bold;
        }
    }

    .mobile-carrier-body {
      width: 100%;
      margin: 16px 0;

      .carrier-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .carrier-label {
          color: $color-primary-company;
          font-weight: bold;
        }
      }
    }

    .mobile-carrier-actions {
      width: 100%;

      .quote-button {
        width: 100%;
        min-width: 140px;
        margin: 5px auto;
        padding: 2% 3%;
        height: 40px;
        border-radius: 15px;
        border: none;
        @include font-small-button-text;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        &--green {
          background: $color-carrier-quote-insurance-button;
          color: $color-carrier-quote-insurance-button-text;
        }

        &--blue {
          background: $color-carrier-quote-button;
          color: $color-white;
        }

        &__text {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .mobile-carrier-details {
      width: 100%;
      border-top: 2px solid $color-gray-dark;
      margin-top: 16px;
      padding-top: 16px;
    }
  }
}

.disabled-button {
  background-color: $color-disabled !important;
}
.disclaimer-info {
  display: flex;
  text-align: left;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
  color: black;
  font-weight: bold;
}
.disclaimer-icon {
  font-weight: bold;
  margin-right: 5px;
  color: $color-secondary-button-border;
}
@media (max-width: 500px) {
  .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 62%;
  }
}
</style>
